
import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';

export const ChartComponent = props => {
	const {
		dataOne,
		dataTwo,
		colors: {
			backgroundColor = '#0b1221',
			lineColorTelos = 'rgba(171, 71, 188, 1)',
			lineColorAvalanche = 'rgba(233,30,99,1)',
			textColor = 'white',
			areaTopColor = 'rgba(171, 71, 188, 0.56)',
			areaBottomColor = 'rgba(171, 71, 188, 0.04)',
			areaTopColorAvax = 'rgba(233,30,99,0.56)',
			areaBottomColorAvax = 'rgba(233,30,99,0.04)',
		} = {},
	} = props;

	const chartContainerRef = useRef();

	useEffect(
		() => {

			const handleResize = () => {
				chart.applyOptions({ width: chartContainerRef.current.clientWidth });
			};

			var formatters = {
				Dollar: function (price) { return '$' + Number(price.toFixed(0)).toLocaleString(); },
				Pound: function (price) { return '\u00A3' + price.toFixed(2); },
			};

			const formatterNames = Object.keys(formatters);

			const chart = createChart(chartContainerRef.current, {
				layout: {
					background: { type: ColorType.Solid, color: backgroundColor },
					textColor,
				},
				width: chartContainerRef.current.clientWidth,
				height: 350,
				grid: {
					vertLines: {
						visible: false,
					},
					horzLines: {
						visible: false,
					},
				},
				handleScroll: false,
				handleScale: false,
				localization: {
					locale: 'en-US',
					priceFormatter: formatters[formatterNames[0]],
				},
			});
			chart.timeScale().fitContent();

			const newSeries = chart.addAreaSeries({ lineColor: lineColorAvalanche, topColor: areaTopColorAvax, bottomColor: areaBottomColorAvax });
			newSeries.setData(dataOne);

			const newSeries2 = chart.addLineSeries({ color: lineColorTelos, lineWidth: 3 });
			newSeries2.setData(dataTwo);

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		},
		[dataOne, dataTwo, backgroundColor, lineColorTelos, lineColorAvalanche, textColor, areaTopColor, areaBottomColor, areaTopColorAvax, areaBottomColorAvax]
	);

	return (
		<div>
			<div ref={chartContainerRef}></div>
		</div>
	);
};
