import React, { useEffect, useRef, useState } from 'react'
import Header from 'app/components/LandingHeader'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import AOS from 'aos'
import { getFactory, useFactory, useMarketData, useSushiPrice, useDayData } from 'app/services/graph'
import { useBurn } from 'app/services/graph/hooks/burn'
import { ethers } from 'ethers'
import { formatNumber } from 'app/functions/format'
import { ChainId } from '@swapsicledex/swapsicle-sdk'
import useTokensAnalytics from 'app/features/analytics/hooks/useTokensAnalytics'
import useSortableData from 'app/hooks/useSortableData'
import { useActiveWeb3React } from 'app/services/web3'
import TokenItem from 'app/components/TokenItem'
import CountUp, { useCountUp } from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { useRouter } from 'next/router'
import logoAnimatio from 'app/animation/Logo_v5.json'
import dexAnimatio from 'app/animation/Dex_V5.json'
import Lottie from 'lottie-react'
import { ChartComponent } from 'app/components/ChartComponant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa1, faBiohazard, faCaretUp, faCheckCircle, faRadiation, faRadiationAlt, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

export default function Landing() {
  AOS.init()
  useEffect(() => {
    document.body.classList.add('landing_page')
    return () => {
      document.body.classList.remove('landing_page')
    }
  }, [])
  const router = useRouter()
  const [isShow1, setIsShow1] = useState(true)
  const { account, chainId } = useActiveWeb3React()
  let chainName = chainId == ChainId.AVALANCHE ? 'Avalanche' : chainId == ChainId.TELOS ? 'Telos' : chainId == ChainId.BSC ? 'BNB' : chainId == ChainId.MATIC ? 'Polygon' : chainId == ChainId.FANTOM ? 'Fantom' : chainId == ChainId.ETHEREUM ? "Ethereum" : chainId == ChainId.OPTIMISM ? "Optimism" : chainId == ChainId.ARBITRUM ? 'Arbitrum' : '';

  const circulatingSupply = 97500000
  const sushiPrice = useSushiPrice()
  const marketData = useMarketData()
  const realMarketCap = sushiPrice ? circulatingSupply * sushiPrice : 0
  const exchangeAVAX = useFactory({ chainId: ChainId.AVALANCHE })
  const exchangeFTM = useFactory({ chainId: ChainId.FANTOM })
  const exchangeMATIC = useFactory({ chainId: ChainId.MATIC })
  const exchangeARB = useFactory({ chainId: ChainId.ARBITRUM })
  const exchangeETH = useFactory({ chainId: ChainId.ETHEREUM })
  const exchangeOPT = useFactory({ chainId: ChainId.OPTIMISM })
  const exchangeBNB = useFactory({ chainId: ChainId.BSC })
  const exchangeTELOS = useFactory({ chainId: ChainId.TELOS })



  const dayDataAvalanche = useDayData({ chainId: ChainId.AVALANCHE })
  const dayDataTelos = useDayData({ chainId: ChainId.TELOS })

  const tokenAnalytics = useTokensAnalytics()

  const { items, requestSort, sortConfig } = useSortableData(
    tokenAnalytics?.filter((e) => {
      return e.volume1d > 0
    }),
    {
      key: 'volume1d',
      direction: 'descending',
    }
  )

  const liquidity = Number(exchangeAVAX?.liquidityUSD) + Number(exchangeARB?.liquidityUSD) + Number(exchangeBNB?.liquidityUSD) + Number(exchangeMATIC?.liquidityUSD) + Number(exchangeFTM?.liquidityUSD) + Number(exchangeOPT?.liquidityUSD) + Number(exchangeETH?.liquidityUSD) + Number(exchangeTELOS?.liquidityUSD)

  const burns = useBurn()
  let total = 0
  let totalUsd = 0

  if (burns != undefined) {
    let burnData = []
    for (let i = 0; i < burns.length; i++) {
      let burn = burns[i]
      let amount = parseFloat(ethers.utils.formatEther(burn.amount))
      let ttl = amount * sushiPrice

      total += amount
      totalUsd += ttl
      var d = new Date(parseInt(burn.timestamp + '000'))
      burnData.push([d.getUTCDate() + '/' + (d.getUTCMonth() + 1) + '/' + d.getUTCFullYear(), ttl])
    }
  }

  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const telosTVLByDay = dayDataTelos?.map(({ date, liquidityUSD }) => ({
    time: new Date(date * 1000).toISOString().substring(0, 10),
    value: parseFloat(liquidityUSD),
  }))
    .filter(({ time }) => new Date(time) >= sixMonthsAgo)
    .sort((a, b) => a.time.localeCompare(b.time));

  const avaxTVLByDay = dayDataAvalanche?.map(({ date, liquidityUSD }) => ({
    time: new Date(date * 1000).toISOString().substring(0, 10),
    value: parseFloat(liquidityUSD),
  }))
    .filter(({ time }) => new Date(time) >= sixMonthsAgo)
    .sort((a, b) => a.time.localeCompare(b.time));


  return (
    <>
      <Head>
        <title>Landing | Swapsicle</title>
      </Head>
      <div className="banner_bg">
        <Image
          src="/images/benner_bg.svg"
          alt="Swapsicle logo"
          layout="fill"
          objectFit="contain"
          objectPosition="center"
        />
      </div>
      <div className="banner_sc">
        <div className="firs_sec_snp_jg">
          <Image
            //src="/images/swapsicle_logo_wt_christmas.svg"
            src="/images/swapsicle_logo_wt.svg"
            alt="Swapsicle logo"
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
          <div className="big_logo">
            <Lottie animationData={logoAnimatio} autoplay loop />
          </div>
          <div className="small_logo">
            <Lottie animationData={dexAnimatio} autoplay loop />
          </div>
        </div>

        <div className="hed_text_box">
          <h6>DeFi with more flavour</h6>
          <p>Securely store, manage & swap your crypto all in one place.</p>
        </div>
      </div>
      <div className="bttm_boxes">
        <div className="header_cont header_cont_max_w">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                router.push('/swap')
              }}
            >

              <div className="mngrdnt_bx clrswp">
                <div className="sec_two_grd_box">
                  <div className="img_width_box">
                    <Image
                      src="/images/sec_two01.svg"
                      alt="Swapsicle logo"
                      layout="fill"
                      objectFit="contain"
                      objectPosition="center"
                    />
                  </div>
                  <h5>Swap</h5>
                </div>
              </div>
            </div>

            <div>
              <Link href="https://pro.swapsicle.io" passHref={true}>
                <div className="mngrdnt_bx clrprtfo">
                  <div className="sec_two_grd_box">
                    <div className="img_width_box">
                      <Image
                        src="/images/Pro_Icon-02.svg"
                        alt="Swapsicle logo"
                        layout="fill"
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </div>
                    <h5>Swapsicle Pro</h5>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link href="/launchpad" passHref={true}>
                <div className="mngrdnt_bx clrpool">
                  <div className="sec_two_grd_box">
                    <div className="img_width_box">
                      <Image
                        src="/icons/launchpad-landing-icon.svg"
                        alt="Swapsicle logo"
                        layout="fill"
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </div>
                    <h5>Launch</h5>
                  </div>
                </div>
              </Link>
            </div>

            {chainId == ChainId.AVALANCHE || chainId === ChainId.TELOS_TESTNET || chainId === ChainId.TELOS ?
              <div>
                <Link href="/farm" passHref={true}>
                  <div className="mngrdnt_bx clrfarm">
                    <div className="sec_two_grd_box">
                      <div className="img_width_box">
                        <Image
                          src="/images/sec_two03.svg"
                          alt="Swapsicle logo"
                          layout="fill"
                          objectFit="contain"
                          objectPosition="center"
                        />
                      </div>
                      <h5>Farm</h5>
                    </div>
                  </div>
                </Link>
              </div>
              :
              <div>
                <Link href={`/browse`} passHref={true}>
                  <div className="mngrdnt_bx clrprtfo">
                    <div className="sec_two_grd_box">
                      <div className="img_width_box">
                        <Image
                          src="/images/sec_two03.svg"
                          alt="Swapsicle logo"
                          layout="fill"
                          objectFit="contain"
                          objectPosition="center"
                        />
                      </div>
                      <h5>Pools</h5>
                    </div>
                  </div>
                </Link>
              </div>
            }

            {
              <div>
                <Link href="/ice-cream-van" passHref={true}>
                  <div className="mngrdnt_bx clriccmvn">
                    <div className="sec_two_grd_box">
                      <div className="img_width_box">
                        <Image
                          src="/images/sec_two04.svg"
                          alt="Swapsicle logo"
                          layout="fill"
                          objectFit="contain"
                          objectPosition="center"
                        />
                      </div>
                      <h5>Stake</h5>
                    </div>
                  </div>
                </Link>
              </div>}



            <div>
              <Link href="https://zombieclub.swapsicle.io/" passHref={true}>
                <div className="mngrdnt_bx clrrwrd">
                  <div className="sec_two_grd_box">
                    <div className="img_width_box">
                      <Image
                        src="/images/sec_two07.svg"
                        alt="Swapsicle logo"
                        layout="fill"
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </div>
                    <h5>Ice Cream Zombies</h5>
                  </div>
                </div>
              </Link>
            </div>


          </div>
        </div>
      </div >
      <div className="any_hed_box">
        <h4>Analytics</h4>
      </div>
      <div className="grd_anlayes">
        <div className="header_cont header_cont_max_w">

          <div className="grid grid-cols-12 gap-4 md:grid-cols-12">

            <div className="col-span-12 md:col-start-1 md:col-span-4" data-aos="fade-left" data-aos-duration="1000">
              <div className="anilys_grd_box">
                <h6>Avalanche TVL</h6>
                <p>
                  <VisibilitySensor partialVisibility active={isShow1}>
                    {({ isVisible }) => {
                      if (isVisible) {
                        setTimeout(function () {
                          setIsShow1(false)
                        }, 2000)
                      }
                      return (
                        <div>
                          {isVisible && isShow1 ? (
                            <CountUp scrollSpyOnce prefix="$" separator="," end={Number(liquidity)} />
                          ) : (
                            formatNumber(Number(exchangeAVAX?.liquidityUSD), true, false)
                          )}
                        </div>
                      )
                    }}
                  </VisibilitySensor>
                </p>
              </div>
            </div>

            <div className="col-span-12 md:col-start-5 md:col-span-4" data-aos="fade-up" data-aos-duration="1000">
              <div className="anilys_grd_box">
                <h6>Total value locked</h6>
                <p>
                  <VisibilitySensor partialVisibility active={isShow1}>
                    {({ isVisible }) => {
                      if (isVisible) {
                        setTimeout(function () {
                          setIsShow1(false)
                        }, 2000)
                      }
                      return (
                        <div>
                          {isVisible && isShow1 ? (
                            <CountUp scrollSpyOnce prefix="$" separator="," end={Number(liquidity)} />
                          ) : (
                            formatNumber(liquidity, true, false)
                          )}
                        </div>
                      )
                    }}
                  </VisibilitySensor>
                </p>
              </div>
            </div>



            <div className="col-span-12 md:col-span-4 md:col-start-9" data-aos="fade-right" data-aos-duration="1000">
              <div className="anilys_grd_box">
                <h6>Telos TVL</h6>
                <p>
                  <VisibilitySensor partialVisibility active={isShow1}>
                    {({ isVisible }) => {
                      if (isVisible) {
                        setTimeout(function () {
                          setIsShow1(false)
                        }, 2000)
                      }
                      return (
                        <div>
                          {isVisible && isShow1 ? (
                            <CountUp scrollSpyOnce prefix="$" separator="," end={Number(liquidity)} />
                          ) : (
                            formatNumber(Number(exchangeTELOS?.liquidityUSD), true, false)
                          )}
                        </div>
                      )
                    }}
                  </VisibilitySensor>
                </p>
              </div>
            </div>


            {/* <div className="col-span-2 d-none-md"></div> */}

            <div className="col-span-12 md:col-start-4 md:col-span-6" data-aos="fade-up" data-aos-duration="1000">
              <div className="anilys_grd_box anilys_grd_box_v2">
                <div className="price_by_grd_box">
                  <div className="swpccl_logo">
                    <Image
                      src="/images/swapsicle.svg"
                      alt="Swapsicle logo"
                      layout="fill"
                      objectFit="contain"
                      objectPosition="center"
                    />
                  </div>
                  <div>
                    <h6>POPS Price</h6>
                    <p>
                      <VisibilitySensor partialVisibility>
                        {({ isVisible }) => {
                          return (
                            <div>
                              {isVisible && isShow1 ? (
                                <CountUp prefix="$" separator="," decimals={2} end={Number(sushiPrice)} />
                              ) : (
                                Number(sushiPrice).toFixed(3).toLocaleString()
                              )}
                            </div>
                          )
                        }}
                      </VisibilitySensor>
                    </p>
                  </div>
                </div>

                <div className="grd_in_grd_box">
                  <div>
                    <h6>Market Cap: </h6>
                    <p>
                      <VisibilitySensor partialVisibility>
                        {({ isVisible }) => {
                          return (
                            <div>
                              {isVisible && isShow1 ? (
                                <CountUp enableScrollSpy prefix="$" separator="," end={Number(realMarketCap)} />
                              ) : (
                                formatNumber(realMarketCap, true, false)
                              )}
                            </div>
                          )
                        }}
                      </VisibilitySensor>
                    </p>
                  </div>
                  <div>
                    <h6>Circulating Supply:</h6>
                    <p>
                      <VisibilitySensor partialVisibility>
                        {({ isVisible }) => {
                          return (
                            <div>
                              {isVisible && isShow1 ? (
                                <CountUp separator="," end={Number(circulatingSupply)} />
                              ) : (
                                formatNumber(circulatingSupply, false, false)
                              )}
                            </div>
                          )
                        }}
                      </VisibilitySensor>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-span-4 col-start-5" data-aos="fade-up" data-aos-duration="1000">
              <div className="anilys_grd_box">
                <h6>Melted POPS</h6>
                <p>
                  <VisibilitySensor partialVisibility>
                    {({ isVisible }) => {
                      return (
                        <div>
                          {isVisible && isShow1 ? (
                            <CountUp separator="," end={Number(total)} />
                          ) : (
                            formatNumber(total, false, false)
                          )}
                        </div>
                      )
                    }}
                  </VisibilitySensor>
                </p>
              </div>
            </div> */}

          </div>
        </div>
      </div>



      <div className="any_hed_box">
        <h4>Chain TVL</h4>
      </div>
      <div className="grd_anlayes">
        <div className="header_cont header_cont_max_w">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-1">
            <div className="col-span-1" data-aos="fade-up" >
              {telosTVLByDay && avaxTVLByDay ?
                <ChartComponent dataTwo={telosTVLByDay} dataOne={avaxTVLByDay}></ChartComponent>
                : <></>}
            </div>
            <div className="col-span-1" data-aos="fade-up" >
              <div className='flex items-center'>
                <FontAwesomeIcon icon={faCaretUp} style={{ color: 'rgba(233,30,99,1)' }} />
                <h2 className='pl-2 pr-4'>Avalanche</h2>
                <FontAwesomeIcon icon={faCaretUp} style={{ color: 'rgba(171, 71, 188, 1)' }} />
                <h2 className='pl-2'>Telos</h2>
              </div>
              <div className='flex items-center'>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="any_hed_box">
        <h4>Swapsicle Ecosystem</h4>
      </div>
      <div className="grd_anlayes">
        <div className="header_cont header_cont_max_w">
          <div className="grid grid-cols-7 gap-0 md:gap-1" data-aos="fade-up">

            <h1></h1>

            <h1 className='flex justify-center text-sm text-white sm:text-xl md:text-3xl landing'>TLOS</h1>
            <h1 className='flex justify-center text-sm text-white sm:text-xl md:text-3xl landing'>AVAX</h1>
            <h1 className='flex justify-center text-sm text-white sm:text-xl md:text-3xl landing'>ARB</h1>
            <h1 className='flex justify-center text-sm text-white sm:text-xl md:text-3xl landing'>BNB</h1>
            <h1 className='flex justify-center text-sm text-white sm:text-xl md:text-3xl landing'>ETH</h1>
            <h1 className='flex justify-center text-sm text-white sm:text-xl md:text-3xl landing'>Other</h1>

            <div className='flex mb-5'></div>

            <div className="flex justify-center mb-5">
              <div className='md:w-[60px] md:h-[60px] w-[40px] h-[40px]'>
                <Image src="/images/tokens/telos.png" alt="Telos logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
              </div>
            </div>

            <div className="flex justify-center mb-5">
              <div className='md:w-[60px] md:h-[60px] w-[40px] h-[40px]'>
                <Image src="/images/avax-logo.svg" alt="AVAX logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
              </div>
            </div>

            <div className="flex justify-center mb-5">
              <div className='md:w-[60px] md:h-[60px] w-[40px] h-[40px]'>
                <Image src="/images/tokens/arbitrum.png" alt="Arb logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
              </div>
            </div>

            <div className="flex justify-center mb-5">
              <div className='md:w-[60px] md:h-[60px] w-[40px] h-[40px]'>
                <Image src="/images/tokens/bnb.png" alt="BNB logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
              </div>
            </div>

            <div className="flex justify-center mb-5">
              <div className='md:w-[60px] md:h-[60px] w-[40px] h-[40px]'>
                <Image src="/images/tokens/eth.png" alt="ETH logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
              </div>
            </div>

            <div className="flex justify-center mb-5">
              <div className="absolute z-10 flex -ml-2 sm:-ml-12">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px]">
                  <Image src="/images/tokens/optimism.png" alt="Others logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
                </div>
              </div>
              <div className="z-20">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px]">
                  <Image src="/images/tokens/fantom.png" alt="Others logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
                </div>
              </div>
              <div className="absolute z-30 flex -mr-2 sm:-mr-12">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px]">
                  <Image src="/images/tokens/matic.png" alt="Others logo" width='0' height='0' sizes="100vw" objectFit="contain" objectPosition="center" className="w-full h-auto" />
                </div>
              </div>
            </div>

            <h1 className="text-xs text-white sm:text-sm md:text-2xl landing">Swaps</h1>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>


            <h1 className='text-xs text-white sm:text-sm md:text-2xl landing'>Pools</h1>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>

            <h1 className='text-xs text-white sm:text-sm md:text-2xl landing'>Farms</h1>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>

            <h1 className='text-xs text-white sm:text-sm md:text-2xl landing'>Staking</h1>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>

            <h1 className="text-xs text-white sm:text-sm md:text-2xl landing sm:before:content-['Launchpad'] before:content-['Launch']"></h1>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>

            <h1 className="text-xs text-white sm:text-sm md:text-2xl landing sm:before:content-['Perpetuals'] before:content-['Perps']"></h1>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faCheckCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#5AF9C4' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faXmarkCircle} className='content-center w-5 h-5 md:w-8 md:h-8' style={{}} /></div>

            <h1 className='text-xs text-white sm:text-sm md:text-2xl landing'>Zombies</h1>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faRadiationAlt} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#FFD780' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faRadiationAlt} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#FFD780' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faRadiationAlt} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#FFD780' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faRadiationAlt} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#FFD780' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faRadiationAlt} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#FFD780' }} /></div>
            <div className='flex justify-center mt-2'><FontAwesomeIcon icon={faRadiationAlt} className='content-center w-5 h-5 md:w-8 md:h-8' style={{ color: '#FFD780' }} /></div>

          </div>
        </div>
      </div>

      <div className="any_hed_box">
        <h4>Launch Partners</h4>
      </div>
      <div className="grd_anlayes">
        <div className="header_cont header_cont_max_w">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3" data-aos="fade-up">

            <div className='flex justify-center order-1'>
              <div className='w-[200px] h-[200px] flex justify-center'>
                <Image src='/images/partners/clout.png' className='drop-shadow-md' alt="Clout logo" width='175px' height='175px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div>
            <div className='flex justify-center order-3 md:order-2'>
              <div className='w-[200px] h-[200px] flex justify-center'>
                <Image src='/images/partners/8bit.png' className='drop-shadow-md' alt="Clout logo" width='175px' height='175px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div>
            <div className='flex justify-center order-5 md:order-3'>
              <div className='w-[200px] h-[200px] flex justify-center'>
                <Image src='/images/partners/fortis.png' className='drop-shadow-md' alt="Clout logo" width='175px' height='175px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div>
            {/* <div className='flex justify-center order-7 md:order-4'>
              <div className='w-[200px] h-[200px] rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 flex justify-center'>
                <Image src='/images/partners/appics.png' className='drop-shadow-md' alt="Clout logo" width='90px' height='90px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div> */}

            <div className='order-2 mb-5 md:order-5 md:mb-0'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>Clout Finance</h1>
              <h2 className='flex justify-center leading-5 text-white text-1xl'>Defi</h2>
            </div>
            <div className='order-4 mb-5 md:order-6 md:mb-0'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>8Bit Arcade</h1>
              <h2 className='flex justify-center leading-5 text-white text-1xl'>Gaming</h2>
            </div>
            <div className='order-6 mb-5 md:order-7 md:mb-0'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>Fortis</h1>
              <h2 className='flex justify-center leading-5 text-white text-1xl'>Wallet</h2>
            </div>
            {/* <div className='order-8 md:order-8'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>Appics</h1>
              <h2 className='flex justify-center text-white text-1xl'>Social Media</h2>
            </div> */}

          </div>

        </div >
      </div>

      <div className="any_hed_box">
        <h4>Boost Partners</h4>
      </div>
      <div className="grd_anlayes">
        <div className="header_cont header_cont_max_w">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4" data-aos="fade-up">

            <div className='flex justify-center order-1'>
              <div className='w-[200px] h-[200px] flex justify-center'>
                <Image src='/images/partners/robinos2.png' className='drop-shadow-md' alt="Robinos logo" width='120px' height='120px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div>
            <div className='flex justify-center order-3 sm:order-2 lg:order-2'>
              <div className='w-[200px] h-[200px] flex justify-center'>
                <Image src='/images/partners/8bit.png' className='drop-shadow-md' alt="Clout logo" width='175px' height='175px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div>
            <div className='flex justify-center order-5 sm:order-5 lg:order-3'>
              <div className='w-[200px] h-[200px] flex justify-center'>
                <Image src='/images/partners/fortis.png' className='drop-shadow-md' alt="Fortis logo" width='175px' height='175px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div>
            <div className='flex justify-center order-7 sm:order-6 lg:order-4'>
              <div className='w-[200px] h-[200px] flex justify-center'>
                <Image src='/images/partners/deco.png' className='drop-shadow-md' alt="Destiny World logo" width='125px' height='125px' objectFit="contain" objectPosition="center"></Image>
              </div>
            </div>

            <div className='order-2 mb-5 lg:mb-0 sm:order-3 lg:order-5'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>Robinos</h1>
              <h2 className='flex justify-center leading-5 text-white text-1xl'>Sports Performance Trading</h2>
            </div>
            <div className='order-4 mb-5 sm:order-4 lg:order-6 lg:mb-5'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>8Bit Arcade</h1>
              <h2 className='flex justify-center leading-5 text-white text-1xl'>Gaming</h2>
            </div>
            <div className='order-6 mb-5 lg:mb-0 sm:order-7 lg:order-7'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>Fortis</h1>
              <h2 className='flex justify-center leading-5 text-white text-1xl'>Wallet</h2>
            </div>
            <div className='order-8 sm:order-8 lg:order-8'>
              <h1 className='flex justify-center mb-3 text-3xl text-white'>Destiny World</h1>
              <h2 className='flex justify-center text-white text-1xl'>Ecosystem</h2>
            </div>

          </div>

        </div >
      </div >

      <div className="top_gainers">
        <div className="table_sc">
          <div className="header_cont header_cont_max_w">
            <h4>Top Volume - {chainName}</h4>
            <div className="ovrfow-y">
              <table className="table-fixed">
                <thead>
                  <tr>
                    <th>Token</th>
                    <th>
                      Volume<span>24H</span>
                    </th>
                    <th>Price</th>
                    <th>24 Hours</th>
                    <th>7 Days</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.length
                    ? items.slice(0, 6).map((token, index) => {
                      return <TokenItem key={index} token={token} />
                    })
                    : null}
                  {/* <tr>
                    <td>
                      <div className="img_tble_txt">
                        <div className="td_img_bx">
                          <Image
                            src="/images/table_01.svg"
                            alt="Swapsicle logo"
                            layout="fill"
                            objectFit="contain"
                            objectPosition="center"
                          />
                        </div>
                        AVAX
                      </div>
                    </td>
                    <td>$56,407,419</td>
                    <td>$25.95</td>
                    <td>-1.67%</td>
                    <td>-4.64%</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
