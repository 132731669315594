import React from "react"
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo"
import { useCurrency } from "../../hooks/Tokens"
import { formatNumber } from '../../functions/format'

export default function TokenItem({ token, key }) {
  const currency = useCurrency(token.token.id)
  return (
    <tr key={key}>
      <td>
        <div className="img_tble_txt">
          <div className="td_img_bx">
            <CurrencyLogo currency={currency} size={24} />
          </div>
          {token.token.symbol}
        </div>
      </td>
      <td>{formatNumber(token.volume1d, true, false, 0)}</td>
      <td>{formatNumber(token.price, true, false, 0)}</td>
      <td>{Number(token.change1d).toFixed(2)} %</td>
      <td>{Number(token.change1w).toFixed(2)} %</td>
    </tr>
  )
}