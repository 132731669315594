import { getBurn } from 'app/services/graph/fetchers/burn'
import stringify from 'fast-json-stable-stringify'
import useSWR, { SWRConfiguration } from 'swr'

interface useBarProps {
  variables?: { [key: string]: any }
  shouldFetch?: boolean
  swrConfig?: SWRConfiguration
}

export function useBurn({ variables, shouldFetch = true, swrConfig = undefined }: useBarProps = {}) {
  // @ts-ignore TYPE NEEDS FIXING
  const { data } = useSWR(shouldFetch ? ['burn', stringify(variables)] : null, () => getBurn(variables), swrConfig)
  return data
}

